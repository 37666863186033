.footer .container {
    max-width: 1000px;
}

.footer {
    background-color: #2D2F31;
}

.footer h3 {
    color: white;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: 22px;
}

.footer-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;

}

.footer-nav li {
    margin-bottom: 12px;
    font-size: 14px;
    color: white;
}

.button5 {
    background-color: #FCF2E8;
    border: none;
}

.footer-logo a {
    text-decoration: none;
    color: #c0c4fc;
    font-weight: 600;
}

.footer-logo .icon {
    font-size: 13px;
}
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap");

:root {
    --color-black: hsl(0, 0%, 10%);
    --color-darks: hsl(0, 0%, 25%);
    --color-greys: hsl(0, 0%, 60%);
    --color-light: hsl(0, 0%, 95%);
    --color-white: hsl(0, 0%, 100%);
    --color-blue-100: hsl(217, 91%, 65%);
    --color-blue-200: hsl(221, 83%, 55%);
    --color-blue-300: hsl(224, 76%, 45%);
}

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    list-style-type: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}



a,
button {
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    text-transform: unset;
}

img,
video {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

img {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
}

/* Elements */

.section {
    margin: 0 auto;
    padding: 5rem 0 2rem;
}

.container {
    max-width: 75rem;
    height: auto;
    margin: 0 auto;
    padding: 0 1.25rem;
}

.brand {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: var(--color-blue-200);
}

/* Header */

.header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 72px;
    z-index: 10;
    margin: 0 auto;
    /* background-color: var(--color-white); */
    background-color: #FFFFFF;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    color: white;
}

.navbar {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 4rem;
    margin: 0 auto;
}

.menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow-y: auto;
    background-color: var(--color-white);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: all 0.5s ease-in-out;
}

.menu.is-active {
    top: 65px;
    left: 0;
    background-color: #F7F9FA;
    /* border: 11px solid red; */
}

.menu-inner {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    margin: 1.25rem;
}

.menu-link {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
    color: var(--color-black);
    transition: all 0.3s ease;
}

.menu-link:hover {
    color: var(--color-blue-200);
}

@media only screen and (max-width: 1024) {
    #inner-section {

        flex-direction: row !important;
        column-gap: 1rem !important;
        margin: 0 auto !important;
        display: none !important;

    }
}

/* Media Query Breakpoint */
@media only screen and (min-width: 48rem) {
    .menu {
        position: relative;
        top: 0;
        left: 0;
        width: auto;
        height: auto;
        margin-left: auto;
        background: none;
        box-shadow: none;
    }

    .menu-inner {
        display: flex;
        flex-direction: row;
        column-gap: 3rem;
        margin: 0 auto;
        margin-right: 5rem;
    }

    .menu-link {
        text-transform: capitalize;
    }

    .menu-block {
        margin-left: 2rem;
    }
}

/* Burger */
.burger {
    position: relative;
    display: block;
    cursor: pointer;
    order: 3;
    width: 1.6rem;
    height: auto;
    border: none;
    outline: none;
    visibility: visible;
}

.burger-line {
    display: block;
    cursor: pointer;
    width: 100%;
    height: 2px;
    margin: 6px auto;
    transform: rotate(0deg);
    border-radius: 1rem;
    background: var(--color-black);
}

/* Media Query Breakpoint */
@media only screen and (min-width: 48rem) {
    .burger {
        display: none;
        visibility: hidden;
    }
}

/* Overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease-in-out;
}

.overlay.is-active {
    display: block;
    opacity: 1;
    visibility: visible;
}

/* ...search bar */
.search-input ::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
}

::placeholder {
    color: gray;
    opacity: 1;
    /* Firefox */
}

.nav-text {
    display: none;
}

.search-form {
    display: flex;
    width: 30%;
    /* border: 1px solid red; */
    align-items: center;
    column-gap: 0.75rem;
    padding: 0.75rem 0.1rem;
    margin-left: 20px;
    color: white;
}

.search-input {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    width: 100%;
    height: auto;
    margin-top: 0;
    padding: 0.65rem 1.25rem;
    border: none;
    outline: none;
    border-radius: 0.25rem;
    /* color: var(--color-black); */
    border-radius: 60px;

    background-color: #F7F9FA;
    border: 1.5px solid black;
    /* background-color: var(--color-light); */
}

.search-icon {
    font-size: 20px;
    color: rgb(220, 216, 216);
    margin-left: -70px;
    margin-top: -8px;
    z-index: 1;
    position: absolute;
}

/* ............... icons section .......... */
/* /.... home*  */

.home-btn i {
    color: var(--white-color);
    font-size: 25px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    display: inline-block;
}

.menu-item .home-btn i:hover {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
}

/* .....notification */

.nav-btn {
    position: relative;
    margin-right: 5px;
}

.nav-btn i {
    color: var(--white-color);
    font-size: 25px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    display: inline-block;
}

.nav-btn i:hover {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
}

.nav-btn .span {
    position: absolute;
    top: -2px;
    right: -5px;
    display: block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    border-radius: 50%;
    font-size: 9px;
    color: var(--white-color);
    text-align: center;
    -webkit-transition: var(--transition);
    transition: var(--transition);
}

.nav-btn .span::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    -webkit-transition: var(--transition);
    transition: var(--transition);
}

.friend-requests-btn .span {
    background-color: #3ed7ff;
}

.messages-btn .span {
    background-color: #52d94d;
}

.notifications-btn .span {
    background-color: #ff3e3e;
}

.notifications-btn .span::before {
    border: 1px solid #ff3e3e;
    -webkit-animation: ripple 3s linear infinite;
    animation: ripple 3s linear infinite;
}

.messages-btn .span::before {
    border: 1px solid #52d94d;
    -webkit-animation: ripple 2s linear infinite;
    animation: ripple 2s linear infinite;
}

.friend-requests-btn .span::before {
    border: 1px solid #3ed7ff;
    -webkit-animation: ripple 3s linear infinite;
    animation: ripple 3s linear infinite;
}

.nav-btn:hover .span {
    background-color: var(--optional-color);
}

/* .....image */

.profile-nav-item::before {
    content: "";
    position: absolute;
    top: 8px;
    left: -12px;
    width: 1px;
    height: 20px;
    background-color: var(--white-color);
    opacity: 25%;
}

.menu-profile img {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 8px;
}

.menu-profile .name {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 600;
}

.menu-profile .status-online {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 30px;
    top: 1px;
    background-color: #1dd217;
    border: 1px solid var(--white-color);
}

/* ......................... */

@media only screen and (max-width: 780px) {
    .navbar {
        /* flex-wrap: wrap-reverse; */
        display: flex;
        flex-direction: row;
        /* background-color: red; */
    }

    .search-form {
        width: 55%;
        /* border: 2px solid blue; */
        margin-left: 10px;
        font-size: 15px;
    }

    .nav-text {
        display: block;
        color: white;
        margin-left: 30px;
    }

    .search-icon {
        font-size: 15px;
    }

    .css-1atzl3b {
        width: 90%;
    }
}

@media only screen and (max-width: 480px) {
    .navbar {
        /* flex-wrap: wrap-reverse; */
        display: flex;
        flex-direction: row;
        /* background-color: red; */
    }

    .search-form {
        width: 50%;
        /* border: 2px solid blue; */
        margin-left: 10px;
        font-size: 15px;
    }

    .search-icon {
        font-size: 14px;
        margin-left: -40px;
    }

    .css-1atzl3b {
        width: 90%;
    }
}
.slider-section img {
    height: 450px;
    width: 100%;
}

.card-section>div {
    width: 25%;
}



.swiper-button-next,
.swiper-button-prev {
    color: white;
}

.popolar-courses .card-title {
    font-weight: 700;
    font-size: 16px;
}

.popolar-courses .card-text {
    color: gray;
}

.popolar-courses .rating {


    padding-left: 0;

}

.rating li span {

    color: gray;
}

.rating .star-icon {

    color: yellowgreen;
}

.rating li:nth-child(2) {
    font-weight: 700;
}


.slider-section .swiper-button-prev:after,
.slider-section .swiper-button-next:after {
    font-size: 31px;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 0;
}

/* testimonial css */

.testmonial {
    background-color: #F7F9FA;

}

.test-body p {
    font-weight: 500;
}

.test-body ul {
    position: relative;
    right: 25px;
}

.test-body ul li {
    color: gray;
    font-size: 12px;
}


/* ---------------media quaries--------------- */

@media screen and (max-width:676px) {
    .slider-section img {
        height: auto;
        width: 100%;
    }

    .slider-section .set_img {
        height: 150px;
        width: 100%;
    }

    .card-section {
        flex-direction: column;
        row-gap: 20px;
    }

    .card-section>div {
        width: 100%;
    }

}



@media screen and (max-width:1024px) {
    .card-section {
        flex-wrap: wrap;
    }

}
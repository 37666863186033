.course-section p{
    color: grey;
}

.course-container .nav-item{
display: inline-block;
margin-right: 20px;
}

.course-container .nav-link{
    border: transparent;
    padding: 0;
    background-color: transparent;
    position: relative;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
    color: #6a6f73;
    line-height: 1.2;
    padding-bottom: 15px;
}

.course-container .nav-link.active{
   
   border-bottom:2px solid black;

}
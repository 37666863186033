.slide_section{
    background-color: #F7F9FA;
}

 .course-item{
background-color: #E4E8EB;
padding: 2px;
border-radius: 40px;
display: flex;
justify-content: center;
align-items: center;
}

 .course-item ul li:nth-child(1){
    font-weight: 700;
    line-height: 1.2;
}

 .course-item ul li:nth-child(2){
font-weight: 400;
font-size: 13px;
margin-top: 2px;
}

 .course-item ul {
    position: relative;
    right: 12px;
    top: 8px;
}

.course-items div:nth-child(1){
    background-color: #2D2F31;
    color: white;
}

.course-items div:nth-child(1) ul li:nth-child(2){
color: white;
}


/* slider section */




@media screen and (max-width:676px) {
   
    .slide_section .card-section{
        flex-direction: column;
        row-gap: 20px;
    }
    .course-items{
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 2px;
    }
   
}

@media screen and (max-width:1024px){
    .slide_section .card-section{
        flex-direction: column;
        row-gap: 20px;
    }
    .course-items{
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 2px;
    }
}

.slide_section .swiper-button-next,.slide_section .swiper-button-prev{
    color:white ;
   background-color: #2D2F31;
    border-radius: 50%;
    width: 45px;
}

.popolar-courses .card-title{
    font-weight: 700;
    font-size: 16px;
}

.popolar-courses .card-text{
    color: gray;
}

.popolar-courses .rating{
    
    
    padding-left: 0;
   
}

.rating li span{
   
    color: gray;
}

.rating .star-icon{
   
    color: yellowgreen;
}
.rating li:nth-child(2) {
    font-weight: 700;
}


.swiper-horizontal > .swiper-pagination-progressbar, .swiper-pagination-progressbar.swiper-pagination-horizontal, .swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite, .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 0px;
    left: 0;
    top: 0;
    display: none;
}


.slide_section .swiper-button-prev:after, .slide_section .swiper-button-next:after {
   
    font-size: 15px;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 0;
}

/* ---------subcourse----------- */

.sub_courses .active{
    background-color: #2D2F31;
    color: white;
}
.hero_title_background{
    height: 500px;
   width: 500px;
    background-repeat: no-repeat;
    margin-right: -20px;
    background-size: contain;
}
.about{
    background-color: #F7F9FA;
}

.hero-title h1{
    font-family: Suisse Works, Georgia, Times, Times New Roman, serif;
    font-weight: 800;
    
}

.desc{
    background-color: black;
    color: white;
    padding: 15px;
}

.desc1{
    padding: 20px;
}

.desc1 p{
   font-family:  Suisse Works, Georgia, Times, Times New Roman, serif;
  font-weight: 500;
  font-size: 17px;
}

.create-impact{
background-color: #5624D0;
color: white;
padding: 30px;
}

@media screen and (max-width:676px){
    .hero_section{
        flex-wrap: wrap;
    }
   
    .hero_title_background{
        margin-right: 0px;
       
    }
    .about{
        margin-top: 90px;
    }
    .create-impact .total{
        flex-wrap: wrap;
    }
    .blog-section{
        flex-wrap: wrap;
    }
}

.contact-container{
    background-color: #F7F9FA;
    padding: 50px;
}

.conatct-card{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    row-gap: 25px;
    column-gap: 50px;
}

.conatct-card ul{
    padding: 0;
}
.conatct-card h4{
    border-bottom: 3px solid black;
    padding-bottom: 20px;
    font-weight: 700;
}

.office-section{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 30px;
    row-gap: 30px;
}
.office-section img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
img {
    overflow-clip-margin: content-box;
    overflow: clip;
}

@media screen and (max-width:676px){
    .conatct-card{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        row-gap: 25px;
        column-gap: 50px;
    }
}